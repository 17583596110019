<template>
  <CDropdown color="black" placement="bottom-end" :caret="false">
    <template #toggler-content>
      <svg
        class="svg-inline--fa fa-user-circle fa-w-16"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-icon="user-circle"
        data-prefix="fal"
        viewBox="0 0 496 512"
      >
        <path
          fill="currentColor"
          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z"
        />
      </svg>
    </template>

    <CDropdownHeader tag="div">
      <div v-if="user && user.nickname">{{ user.nickname }}</div>
    </CDropdownHeader>
    <CDropdownItem @click="$router.push('/user/profile')">
      {{ $t("header.account.profile") }}
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/email')">
      {{ $t("header.account.mail") }}
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/social')">
      {{ $t("header.account.social") }}
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/families')">
      {{ $t("header.account.family") }}
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout()">
      {{ $t("header.account.logout") }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "UiHeaderUserSettings",
  computed: {
    user() {
      return this.$auth.user() || {};
    },
  },
  methods: {
    logout() {
      this.$auth
        .logout({
          makeRequest: true,
        })
        .then(() => {
          this.$store.dispatch("resetState");
        });
    },
  },
};
</script>
